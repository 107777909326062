import { frontloadConnect } from 'react-frontload';
import { connect } from 'react-redux';
import { OrganizationProps } from '@nykaa/jsonld-shared/schemas/Organization';
import { DeepSearchProps } from '@nykaa/jsonld-shared/schemas/DeepSearch';
import {
  generateOrganizationSchema as _generateOrganizationSchema,
  generateDeepSearchSchema as _generateDeepSearchSchema,
  JSONLdData,
} from '@nykaa/jsonld-shared/store/action';

import { fetchHomePageWidgets as _fetchHomePageWidgets } from 'shared/store/home/action';
import { getCustomPageType } from 'shared/store/home/helper';
import View from 'shared/layouts/Home';
import { State } from 'store/types';
import { FetchHomePageParams } from 'store/home/types';
import { HomePageConfig } from 'store/remoteConfig/types';
import { RouteComponentProps } from 'react-router-dom';
import { PagePrefixes } from 'constants/pagePrefixes';

const mapStateToProps = ({ homePage, remoteConfig }: State) => ({
  widgets: homePage.widgets,
  styles: homePage.styles,
  loading: homePage.loading,
  configs: remoteConfig.configs.homePage,
  isFetchingError: !!homePage.isFetchingError,
  loadInterFont: remoteConfig.switches.loadInterFont,
  organizationSeoSchema: remoteConfig?.configs.organizationSeoSchema,
  isPaginationEnabled: !!remoteConfig?.switches?.isPaginationEnabled,
  remainingInventoryIds: homePage?.remaining_inventories_ids || [],
  queryParamsObject: homePage?.queryParams || {},
  deepSearchSeoSchema: remoteConfig?.configs?.deepSearchSeoSchema,
  homeh1Tag: remoteConfig?.configs.homeh1Tag,
});

const mapDispatchToProps = {
  fetchHomePageWidgets: _fetchHomePageWidgets,
  generateOrganizationSchema: _generateOrganizationSchema,
  generateDeepSearchSchema: _generateDeepSearchSchema,
};

interface FetchDataParams extends RouteComponentProps<void> {
  fetchHomePageWidgets: (
    params: FetchHomePageParams,
    pagePrefix?: string,
    isPaginationEnabled?: boolean
  ) => Promise<void>;
  staticContext?: CustomStaticContext;
  configs: HomePageConfig;
  isPaginationEnabled: boolean;
  generateOrganizationSchema: (data: JSONLdData) => void;
  organizationSeoSchema: OrganizationProps;
  generateDeepSearchSchema: (arg: JSONLdData) => void;
  deepSearchSeoSchema: DeepSearchProps;
}

const fetchData = async ({
  fetchHomePageWidgets,
  staticContext,
  configs,
  location,
  isPaginationEnabled,
  generateOrganizationSchema,
  organizationSeoSchema = {},
  generateDeepSearchSchema,
  deepSearchSeoSchema,
}: FetchDataParams) => {
  const { storeId } = staticContext || {};
  const queryParams = new URLSearchParams(location.search);
  const customPageTypeInQuery = queryParams.get('customPageType');
  const customPageType = getCustomPageType(configs, customPageTypeInQuery);
  if (organizationSeoSchema) {
    generateOrganizationSchema(organizationSeoSchema);
  }
  if (deepSearchSeoSchema) {
    generateDeepSearchSchema(deepSearchSeoSchema);
  }
  return fetchHomePageWidgets(
    {
      ...(storeId && { store: storeId }),
      ...(customPageType && { page_type: customPageType }),
      ...(isPaginationEnabled && { api_version: 'V2' }),
    },
    PagePrefixes.HOMEPAGE,
    isPaginationEnabled
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(frontloadConnect(fetchData)(View));
